<!--<section class="reg-box">
    <div class="w-100 mb-auto scrollable">
        <p-stepper [(activeStep)]="currentStep" [linear]="true" [orientation]="stepperOrientation" class="w-100">
            <p-stepperPanel>
                <ng-template pTemplate="header" let-index="index">
                    <span class="p-stepper-number">
                        <i *ngIf="index < currentStep" class="pi pi-check"></i>
                        <span *ngIf="index >= currentStep">{{index + 1}}</span>
                    </span>
                    <span class="p-stepper-title">Exhibitor Information</span>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-exhibitor-info #exhibitorInfo [states]="states" [orgId]="orgId" [registration]="registration"
                        [counties]="counties" [chapters]="chapters" [orgConfigs]="orgConfigs"></app-exhibitor-info>
                </ng-template>
            </p-stepperPanel>
            <p-stepperPanel>
                <ng-template pTemplate="header" let-index="index">
                    <span class="p-stepper-number">
                        <i *ngIf="index < currentStep" class="pi pi-check"></i>
                        <span *ngIf="index >= currentStep">{{index + 1}}</span>
                    </span>
                    <span class="p-stepper-title">Shows</span>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-livestocks #livestocksInfo [orgId]="orgId" [registration]="registration"></app-livestocks>
                </ng-template>
            </p-stepperPanel>
            <p-stepperPanel>
                <ng-template pTemplate="header" let-index="index">
                    <span class="p-stepper-number">
                        <i *ngIf="index < currentStep" class="pi pi-check"></i>
                        <span *ngIf="index >= currentStep">{{index + 1}}</span>
                    </span>
                    <span class="p-stepper-title">Disclosures</span>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-additional-info #additionalInfo [registration]="registration"
                        [w9File]="w9File"></app-additional-info>
                </ng-template>
            </p-stepperPanel>
            <p-stepperPanel>
                <ng-template pTemplate="header" let-index="index">
                    <span class="p-stepper-number">
                        <i *ngIf="index < currentStep" class="pi pi-check"></i>
                        <span *ngIf="index >= currentStep">{{index + 1}}</span>
                    </span>
                    <span class="p-stepper-title">Payment</span>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-payment #paymentInfo [orgId]="orgId" [registration]="registration" [w9File]="w9File"
                        (onPaymentComplete)="onPaymentSuccess($event)"
                        (previousClick)="onStepNavigation('prev')"></app-payment>
                </ng-template>
            </p-stepperPanel>
            <p-stepperPanel>
                <ng-template pTemplate="header" let-index="index">
                    <span class="p-stepper-number" [ngClass]="{'completed': paymentResponse}">
                        <i *ngIf="index < currentStep || paymentResponse" class="pi pi-check"></i>
                        <span *ngIf="index >= currentStep && !paymentResponse">{{index + 1}}</span>
                    </span>
                    <span class="p-stepper-title" [ngClass]="{'completed': paymentResponse?.status}">Confirmation</span>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-confirmation [paymentResponse]="paymentResponse"></app-confirmation>
                </ng-template>
            </p-stepperPanel>
        </p-stepper>
    </div>
    <div *ngIf="currentStep != steps.Confirmation" class="mt-3 w-100">
        <div class="row">
            <div class="col-md d-flex justify-content-end gap-2">
                <p-button #prevBtn *ngIf="currentStep != steps.Payment && currentStep != steps.ExhibitorInfo"
                    label="Back" [outlined]="true" [rounded]="true" (onClick)="onStepNavigation('prev')" />
                <p-button #nextBtn *ngIf="currentStep != steps.Payment" label="Continue" [rounded]="true"
                    (onClick)="onStepNavigation('next')" />
            </div>
        </div>
    </div>
</section>-->
<section class="reg-box">
    <div *ngIf="isRegistrationNotStarted" class="reg-err">
        <div class="reg-err-msg">Registrations are not yet started!</div>
        <div class="reg-err-help">For any questions, please email to <a
                href="mailto:thegrandstand21&#64;gmail.com">thegrandstand21&#64;gmail.com</a></div>
    </div>
    <div *ngIf="isRegistrationCompleted" class="reg-err">
        <div class="reg-err-msg">Registrations are closed!</div>
        <div class="reg-err-help">For any questions, please email to <a
                href="mailto:thegrandstand21&#64;gmail.com">thegrandstand21&#64;gmail.com</a></div>
    </div>
    <div *ngIf="isRegistrationStarted">
        <div class="w-100 mb-auto scrollable">
            <div class="mb-3">
                <div class="d-flex justify-content-between align-items-center">
                    <div [ngSwitch]="currentStep" class="steps-title">
                        <div *ngSwitchCase="steps.ExhibitorInfo">Exhibitor Information</div>
                        <div *ngSwitchCase="steps.Livestocks">Shows</div>
                        <div *ngSwitchCase="steps.Disclosures">Disclosures</div>
                        <div *ngSwitchCase="steps.Payment">Payment</div>
                        <div *ngSwitchCase="steps.Confirmation">Confirmation</div>
                    </div>
                    <div class="text-end steps"><span class="current-step">Step {{currentStep}}</span> of 5</div>
                </div>
                <p-progressBar [value]="getProgress()" class="progressbar mt-2"></p-progressBar>
            </div>
            <div class="mt-2" [ngSwitch]="currentStep">
                <app-exhibitor-info *ngSwitchCase="steps.ExhibitorInfo" #exhibitorInfo [states]="states" [orgId]="orgId"
                    [registration]="registration" [counties]="counties" [chapters]="chapters"
                    [orgConfigs]="orgConfigs"></app-exhibitor-info>
                <app-livestocks *ngSwitchCase="steps.Livestocks" #livestocksInfo [orgId]="orgId"
                    [registration]="registration"></app-livestocks>
                <app-additional-info *ngSwitchCase="steps.Disclosures" #additionalInfo [registration]="registration"
                    [w9File]="w9File"></app-additional-info>
                <app-payment *ngSwitchCase="steps.Payment" #paymentInfo [orgId]="orgId" [registration]="registration"
                    [w9File]="w9File" (onPaymentComplete)="onPaymentSuccess($event)"
                    (previousClick)="onStepNavigation('prev')"></app-payment>
                <app-payment *ngSwitchCase="steps.Confirmation" #paymentInfo [orgId]="orgId"
                    [registration]="registration" [registrationResponse]="registrationResponse"></app-payment>
            </div>
        </div>
        <div *ngIf="currentStep != steps.Confirmation" class="mt-3 w-100">
            <div class="row">
                <div class="col">
                    <p-button #prevBtn *ngIf="currentStep != steps.Payment && currentStep != steps.ExhibitorInfo"
                        label="Back" [outlined]="true" [rounded]="true" (onClick)="onStepNavigation('prev')" />
                </div>
                <div class="col text-end">
                    <p-button #nextBtn *ngIf="currentStep != steps.Payment" label="Continue" [rounded]="true"
                        (onClick)="onStepNavigation('next')" />
                </div>
            </div>
        </div>
    </div>
</section>