<section *ngIf="!isOrgConfigsLoaded" class="d-flex justify-content-center align-items-center w-100 h-100">
    <p-progressSpinner />
</section>
<section *ngIf="isOrgConfigsLoaded" class="w-100 h-100">
    <p-confirmDialog></p-confirmDialog>
    <div class="main-body">
        <div class="d-none d-lg-block left-section">
            <app-sidebar [registrationDates]="registrationDates" [eventDates]="eventDates"></app-sidebar>
        </div>
        <div class="right-section">
            <p-toast [position]="'bottom-center'"></p-toast>
            <img src="assets/main-content-background-tl.png" class="main-content-background-tl" alt="top" />
            <img src="assets/main-content-background-br.png" class="main-content-background-br" alt="top" />
            <main class="box shadow-lg">
                <router-outlet></router-outlet>
            </main>
            <app-footer class="footer"></app-footer>
        </div>
    </div>
</section>