import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { RegistrationService } from './registration.service';
import { State } from './models/state';
import { ExhibitorInfoComponent } from './exhibitor-info/exhibitor-info.component';
import { Livestock, Registration, RegistrationResponse } from './models/registration';
import { PaymentComponent } from './payment/payment.component';
import { Steps } from './models/steps.enum';
import { LivestocksComponent } from './livestocks/livestocks.component';
import { NotificationService } from '../shared/service/notification.service';
import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { County } from './models/county';
import { Chapter } from './models/chapter';
import { OrgConfigs } from '../shared/models/org-configs';
import { DataService } from '../shared/service/dataService';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.scss'
})
export class RegistrationComponent implements OnInit {

  @ViewChild('exhibitorInfo') exhibitorInfo!: ExhibitorInfoComponent;
  @ViewChild('livestocksInfo') livestocksInfo!: LivestocksComponent;
  @ViewChild('additionalInfo') additionalInfo!: AdditionalInfoComponent;
  @ViewChild('paymentInfo') paymentInfo!: PaymentComponent;

  minQty: number = 0;
  stepperOrientation: 'vertical' | 'horizontal' = 'horizontal';
  currentStep: Steps = Steps.ExhibitorInfo;
  steps = Steps;
  states: State[] = [];
  counties: County[] = [];
  chapters: Chapter[] = [];
  registration: Registration = { state: 'TX' };
  w9File: File | undefined
  registrationResponse: RegistrationResponse | undefined;
  orgId: number = 0;
  orgConfigs: OrgConfigs | undefined;
  isRegistrationNotStarted: boolean = false;
  isRegistrationCompleted: boolean = false;
  isRegistrationStarted: boolean = false;

  constructor(
    private registrationService: RegistrationService,
    private notificationService: NotificationService,
    private dataService: DataService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateOrientation();
  }

  updateOrientation() {
    const width = window.innerWidth;
    this.stepperOrientation = width < 768 ? 'vertical' : 'horizontal';
  }

  ngOnInit(): void {
    this.updateOrientation();

    this.states = this.registrationService.getStates();
    this.orgConfigs = this.dataService.orgConfigs;
    this.orgId = this.orgConfigs.orgId;
    this.registration.orgId = this.orgConfigs.orgId;

    const orgConfigs: OrgConfigs = this.dataService.orgConfigs;

    const currentDate = new Date();
    const startDate = new Date(orgConfigs.events.registrationStart);
    const endDate = new Date(orgConfigs.events.registrationEnd);

    if (currentDate <= startDate) {
      this.isRegistrationNotStarted = true;
    } else if (currentDate >= endDate) {
      this.isRegistrationCompleted = true;
    } else {
      this.isRegistrationStarted = true;

      this.registrationService.getCounties().subscribe({
        next: response => {
          this.counties = response.sort((a, b) => a.name.localeCompare(b.name));
        },
        error: err => {
          console.error(err);
          this.notificationService.error();
        }
      });

      this.registrationService.getChapters().subscribe({
        next: response => {
          this.chapters = response.sort((a, b) => a.chapter.localeCompare(b.chapter));;
        },
        error: err => {
          console.error(err);
          this.notificationService.error();
        }
      });
    }
  }

  onStepNavigation(action: 'next' | 'prev') {
    if (this.currentStep == Steps.ExhibitorInfo) {
      if (this.registration.firstName
        && this.registration.lastName
        && this.registration.ageDiscloserRequired
        && !this.registration.ageDiscloserAccepted) {
        this.exhibitorInfo.showAgeDisclosures();
      } else if (!this.exhibitorInfo.validateForm()) {
        this.notificationService.error("Please provide all required details to proceed.");
      } else {
        this.registration = this.exhibitorInfo.getRegistrationDetails();
        if (!this.registration.qualityCounts) {
          this.notificationService.error('You cannot proceed without Quality Counts.', true);
        } else {
          this.currentStep++;
        }
      }
    } else if (this.currentStep == Steps.Livestocks) {
      const livestocks: Livestock[] = this.livestocksInfo.getLivestocks();
      this.registration.liveStocks = livestocks;

      var totalPayable = 0;
      livestocks.flatMap(ls => ls.totalPrice).forEach(total => { totalPayable += total; });
      this.registration.totalPayable = totalPayable;

      //this.registration.liveStocks.forEach(ls => ls.expanded = false);

      if (action == 'next' && livestocks.length == 0) {
        this.notificationService.error("Please add atleast one entry to proceed.");
      } else {
        if (action == 'next') {
          this.currentStep++;
        } else {
          this.currentStep--;
        }
      }
    } else if (this.currentStep == Steps.Disclosures) {
      this.w9File = this.additionalInfo.getW9File();
      //this.registration.signingDetails = signingDetails;
      //this.registration.transactionId = signingDetails?.id;

      if (action == 'next' && (!this.w9File || !this.registration.waiverComplete)) {
        this.notificationService.error("Please upload W-9 & sign Waiver Form to proceed.");
      } else {
        if (action == 'next') {
          this.currentStep++;
        } else {
          this.currentStep--;
        }
      }
    } else if (this.currentStep == Steps.Payment) {
      //this.registration.liveStocks?.forEach(ls => ls.expanded = false);

      if (action == 'prev') {
        this.currentStep--;
      }
    }
  }

  onPaymentSuccess(registrationResponse: RegistrationResponse) {
    if (registrationResponse && registrationResponse.paymentResponse.status) {
      this.registrationResponse = registrationResponse;
      this.currentStep++;
    }
  }

  getProgress(): number {
    return this.currentStep * 100 / (Object.keys(Steps).length / 2);
  }

}
